import {ContentViewModel,ContentView, ProfileFieldsHelper, ErrorFields, Application, Managers, AuthManager} from '@levelapp/softfabric';
import ResetViewState from './ResetViewState';
import ResetViewProps from './ResetViewProps';
import Token from '@levelapp/softfabric/Architecture/Managers/DTO/Token';
import PasswordOperation from '../../../Transfer/PasswordOperation';
import ToastHelper from '../../../Common/Helper/ToastHelper';

export default class ResetViewModel extends ContentViewModel<ResetViewState, ResetViewProps>
{
    _authManager : AuthManager = Application.current.resolve<AuthManager>(Managers.AUTH_MANAGER);
    _passwordOperation : PasswordOperation;
    /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        var token : Token = 
        {
            access_token : "",
            expires_in : 0,
            refresh_token : "",
            token_type : ""
        }
        try
        {
            var split = document.URL.split('?');      
            var params = this.parseParams(split[1]);
            var result = params.find(x => x.key == "token");
            token.access_token = result != undefined ? result.value : "";
        }catch(error)
        {

        }
        
        //this._authManager.setToken(token);
        this.initialState(
            {
                email : '',
                password : '',
                confirmpassword : '',
                token : token.access_token,
                isLoading : false,
                success : false,

                isCorrectPassword : true,
                isCorrectEmail : true,
                isCorrectConfirmPassword : true,


            }); 

        // Bindings
        this.verifyEmail = this.verifyEmail.bind(this);
        this.verifyPassword = this.verifyPassword.bind(this);
        this.verifyConfirmPassword = this.verifyConfirmPassword.bind(this);

        
        this.resetPassword = this.resetPassword.bind(this);
        this.handleEmailChanged = this.handleEmailChanged.bind(this);
        this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
        this.handleConfirmPasswordChanged = this.handleConfirmPasswordChanged.bind(this);
        this.handleFailurePassword = this.handleFailurePassword.bind(this);
        this.handleSuccessPassword = this.handleSuccessPassword.bind(this);

        this._passwordOperation = new PasswordOperation();
    }


    resetPassword()
    {
        if(this.checkFields())
        {
            this.setField({isLoading : true});
            this._passwordOperation.reset(
                {
                    UserName : this.state().email,
                    Password : encodeURI(this.state().password),
                    ConfirmPassword : encodeURI(this.state().confirmpassword),
                    Token : this.state().token,
                },this.handleSuccessPassword, this.handleFailurePassword)
        }
    }

    handleSuccessPassword()
    {
        this.setField({success : true , isLoading : false});
    }

    handleFailurePassword()
    {
        this.showToast(ToastHelper.getToast("generic.error","error"),() => {},2500);
        this.setField({isLoading : false});
    }

    checkFields()
    {
        this.setField({extract : true});
        var tmpEmail = this.verifyEmail(this.state().email);

        var tmpP = this.verifyPassword(this.state().password);
        var tmpCP = this.verifyConfirmPassword(this.state().confirmpassword);

        if(
            tmpEmail && 
            tmpP && 
            tmpCP)
        {
            //Form Operation
            return true;
        }else
        {

            return false;
        }
    }

    parseParams (queryParams: string)
    {
        var array = [];

        const params = queryParams.split('&');

        for (let i = 0; i < params.length; i++)
        {
            const query = params[i].split('=');

            const keyValue =
            {
                key : query[0],
                value : query[1]
            };

            array.push(keyValue);
        }

        return array;
    }

    handleEmailChanged(text : string)
    {
        text = text.trim();
        this.verifyEmail(text);
        this.setField({email : text});
    }

    handlePasswordChanged(text : string)
    {
        text = text.replace(/\s/g,'');
        this.verifyPassword(text);
        this.setField({password : text});
    }

    handleConfirmPasswordChanged(text : string)
    {
        text = text.replace(/\s/g,'');
        this.verifyConfirmPassword(text);
        this.setField({confirmpassword : text});
    }

    private verifyEmail(value : string) : boolean
    {
        var num = ProfileFieldsHelper.verifyEmail(value);
        if(ErrorFields.EMAIL_NULL_OR_EMPTY == num)
        {
            this.setField({isCorrectEmail : false});
            return false;
        }

        if(ErrorFields.EMAIL_REGEX == num)
        {
            this.setField({isCorrectEmail : false});
            return false;
        }
    
        this.setField({isCorrectEmail : true});
        return true;
    }

    private verifyPassword(value : string) : boolean
    {
        
        var num = ProfileFieldsHelper.verifyPasswordAdvanced(value);
        if(ErrorFields.PASSWORD_NULL_OR_EMPTY == num)
        {
            this.setField({isCorrectPassword : false});
            return false;
        }

        if(ErrorFields.PASSWORD_MAXIMUM_LENGTH == num)
        {
            this.setField({isCorrectPassword : false});
            return false;
        }

        if(ErrorFields.PASSWORD_MINIMUM_LENGTH == num)
        {
            this.setField({isCorrectPassword : false});
            return false;
        }

        if(ErrorFields.PASSWORD_AT_LEAST_ONE_NUMBER == num)
        {
            this.setField({isCorrectPassword : false});
            return false;
        }  

        if(ErrorFields.PASSWORD_AT_LEAST_ONE_UPPERCASE == num)
        {
            this.setField({isCorrectPassword : false});
            return false;
        }

        if(ErrorFields.PASSWORD_AT_LEAST_ONE_LOWERCASE == num)
        {
            this.setField({isCorrectPassword : false});
            return false;
        }

    
        this.setField({isCorrectPassword : true});
        return true;
    }

    private verifyConfirmPassword(value : string) : boolean
    {
        var num = ProfileFieldsHelper.verifyTextFields(value);
        if(ErrorFields.FIELDS_NULL_OR_EMPTY == num)
        {
            this.setField({isCorrectConfirmPassword : false});
            return false;
        }

        if(ErrorFields.FIELDS_MAXIMUM_MENGTH == num)
        {
            this.setField({isCorrectConfirmPassword : false});
            return false;
        }


        if(value != this.state().password)
        {
            this.setField({isCorrectConfirmPassword : false});
            return false;
        }
    
        this.setField({isCorrectConfirmPassword : true});
        return true;
    }
}