import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import TextInputGenericViewState from './TextInputGenericViewState';
import TextInputGenericViewProps from './TextInputGenericViewProps';
import { Animated } from 'react-native';

export default class TextInputGenericViewModel extends ContentViewModel<TextInputGenericViewState, TextInputGenericViewProps>
{
    translation : Animated.Value;
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState(
            {
                value : ''
            }); // Initialize your state here

        // Bindings
        this.leaveAnimation = this.leaveAnimation.bind(this);
        this.launchAnimation = this.launchAnimation.bind(this);

        this.handleChangeValue = this.handleChangeValue.bind(this);

        this.translation = new Animated.Value(0);
    }

    handleChangeValue(value : string)
    {
        this.setField({value : value});
        this.props().handleChangeText(value)
    }

    leaveAnimation()
    {

        if(this.state().value == "")
        {
            Animated.spring(this.translation,
                {
                    toValue : 0
                }).start();
        }

    }

    launchAnimation()
    {
        Animated.spring(this.translation,
            {
                toValue : 1
            }).start();
    }
}