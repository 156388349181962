import { Platform, Dimensions } from "react-native";

export default class ResponsiveHelper
{
    static isSmarthphone()
    {
        
        if(Platform.OS == 'android' || Platform.OS == 'ios' || Dimensions.get('window').width <= 700)
        {
            
            return true;
        }
        else
        {
            return false;
        }

    }

} 