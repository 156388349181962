import {ContentViewStyle, Application} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle, Dimensions, ImageStyle} from 'react-native';
import WebConstants from '../../../Common/Constants/WebConstants';
import { StyleManager, UiManagers } from '@levelapp/softfabric-ui';
import ResponsiveHelper from '../../../Common/Helper/ResponsiveHelper';

export default class ResetViewStyle extends ContentViewStyle
{
    MyManagerStyle = Application.current.resolve<StyleManager>(UiManagers.STYLE_MANAGER);

    container: ViewStyle = {
        height: Dimensions.get('window').height,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    }

    image: StyleProp<ImageStyle> = {
        zIndex : 1 ,
        height : 115 , 
        width : 115 , 
        justifyContent : 'center' , 
        alignSelf : 'center',
        transform : [{translateY : 55}]
    }

    imageForPhone: StyleProp<ImageStyle> = {
        zIndex : 1 ,
        height : 115 , 
        width : 115 , 
        justifyContent : 'center' , 
        alignSelf : 'center',
        marginVertical  : 20
    }

    headerText : StyleProp<TextStyle> =
    {
        color : WebConstants.APP_COLOR,
        fontSize : WebConstants.TITLE_FONT,
        fontFamily : WebConstants.APP_FONT,
        fontWeight : "700",
        textAlign : 'center',
        paddingVertical : 30,
    }

    loginBox: StyleProp<any> = {
        height : ResponsiveHelper.isSmarthphone() ? '80%' : '70%',
        width: 560,
        backgroundColor: 'white',
        borderRadius : 15,
        boxShadow: "0 4px 10px -1px rgba(135,135,135,0.5)"

    }
    

    boxTitle: ViewStyle = {
        width: 400,
        height: 150,
        borderRadius : 5,
        backgroundColor: this.MyManagerStyle.primaryColor,
        marginTop: -20,
        margin: 'auto'
    }

    title: TextStyle = {
        color: 'white',
        fontSize: 50,
        textAlign: 'center',
        padding: 40
    }

    loginContainer: ViewStyle = {
        display: 'flex',
        alignSelf: 'center',
        justifyContent: 'space-around',
        paddingHorizontal : 110,
        paddingVertical : 50,
        flex : 1,
        height: 250,
        width: '100%',
    }

    footer : StyleProp<ViewStyle> =
    {
        backgroundColor : WebConstants.APP_COLOR, 
        position : 'absolute' , 
        bottom : 0 , 
        width : '100%' , 
        height : 95,
        flexDirection : 'row'

    }

}