import { Service, Application, HttpManager, Managers } from "@levelapp/softfabric";
import PathConstants from "../Common/Constants/PathConstants";
import FormPassword from "../Common/DTO/FormPassword";

export default class PasswordService extends Service
{
    httpClient = Application.current.resolve<HttpManager>(Managers.HTTP_MANAGER);

    async resetPassword(obj : FormPassword , onSuccess: Function , onFailure : Function)
    {       
        this.httpClient.handleSomethingWentWrong = onFailure;

        this.httpClient
        .post(`${PathConstants.PASSWORD_POST_BASE}`,[],obj)
        .then((response : any) => {
            console.log(response);
            if (response)
            {
                                 
                onSuccess();
              
            }
        });
    }

   

    
}