import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';
import WebConstants from '../../../Common/Constants/WebConstants';

export default class ButtonGenericViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        borderWidth : 1 , 
        borderRadius : 3,
        borderColor : WebConstants.APP_SECOND_COLOR,
        width : '100%',
        flex : 1,
        justifyContent : 'center' ,
        alignItems : 'center',

    }

    active: StyleProp<ViewStyle> = {
        backgroundColor : WebConstants.APP_SECOND_COLOR,
    }

    cosmos : StyleProp<ViewStyle> = {
        paddingVertical : 30,
        width : '100%'
    }

    text : StyleProp<TextStyle> =
    {
        fontSize : WebConstants.TEXTINPUT_FONT ,
        fontFamily : WebConstants.APP_FONT,
        fontWeight : "700",
        paddingVertical : 14,
    }

    textActive : StyleProp<TextStyle> =
    {
        color : 'white'
    }

    textReverse : StyleProp<TextStyle> =
    {
    }


}