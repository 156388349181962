import {ContentViewStyle} from '@levelapp/softfabric';
import {StyleProp,ViewStyle, TextStyle} from 'react-native';
import WebConstants from '../../../Common/Constants/WebConstants';

export default class TextInputGenericViewStyle extends ContentViewStyle
{
    container: StyleProp<ViewStyle> = {
        borderColor : WebConstants.BORDER_COLOR,
        borderWidth : 1,
        backgroundColor : 'white',
        borderRadius : 3,
        paddingHorizontal : 16,
        paddingVertical : 12,
        maxWidth : '100%',

    }

    containerError : StyleProp<ViewStyle> =
    {
        borderWidth : 1,
        borderColor : WebConstants.ERROR_COLOR,
    }

    textInput : StyleProp<TextStyle> =
    {
        color : WebConstants.APP_COLOR,
        fontFamily : WebConstants.APP_FONT,
        fontWeight : "400"
    }

    error : StyleProp<ViewStyle> =
    {
        paddingTop : 10,
        paddingBottom : 15,
    }


    errorText : StyleProp<TextStyle> =
    {
        color : WebConstants.NO_ERROR_COLOR
    }

}