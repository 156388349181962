import ClientConstants from "../../ClientConstants";

export default class WebConstants 
{
    public static APP_COLOR = '#4A4A4A';
    public static APP_SECOND_COLOR= ClientConstants.CLIENT_PRIMARY_COLOR;
    public static POPUP_COLOR= "#EDEDED";
    public static NO_ERROR_COLOR = "#959595";

    public static ERROR_COLOR = "#C53730";
    public static BORDER_COLOR = "#B5B5B5";
    public static TEXT_INPUT_COLOR = "#8C8C8C";



    //TODO: FONTS
    //Open Sans Bold
    public static APP_FONT = "Open Sans";

    //Open Sans Regular
    public static APP_FONT_REGULAR = "400";


    public static TITLE_FONT = 30;
    public static SUB_TITLE_FONT = 26;
    public static TEXTINPUT_FONT = 16;
    public static BUTTON_TEXT_FONT = 12;


}