import { Operation } from '@levelapp/softfabric';
import PasswordService from '../Proxy/PasswordService';
import FormPassword from '../Common/DTO/FormPassword';


export default class PasswordOperation extends Operation
{
    _service : PasswordService;

    constructor()
    {
        super();
        this._service = new PasswordService();
    }

    async reset(form :  FormPassword, callback: Function = () => { }, failure: Function = () => { })
    {
        await (this._service as PasswordService).resetPassword(form,callback,failure);
    }


}