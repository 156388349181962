import ClientConstants from "../../ClientConstants";

export default class PathConstants
{

    public static readonly SERVICES = ClientConstants.IDENTITY;

    private static readonly BASE =  "api";

    //RESET
    private static readonly PASSWORD_BASE = `${PathConstants.BASE}/ResetPassword`
        //POST
        public static readonly PASSWORD_POST_BASE = `${PathConstants.PASSWORD_BASE}/ApiResetPassword`;

    
} 