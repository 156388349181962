import React from 'react';
import { View } from 'react-native';
import ShellViewState from './ShellViewState';
import ShellViewProps from './ShellViewProps';
import ShellViewStyle from './ShellViewStyle';
import ShellViewModel from './ShellViewModel';
import { Switch, Route, Redirect } from 'react-router';
import { ContentView, Application, NavigationManager, Managers, LanguageManager } from '@levelapp/softfabric';
import ResetView from './modules/reset/ResetView';

export default class ShellView extends ContentView<ShellViewProps, ShellViewState, ShellViewModel, ShellViewStyle>
{
  NavigationManager : NavigationManager = Application.current.resolve<NavigationManager>(Managers.NAVIGATION_MANAGER);
  LanguageManager : LanguageManager = Application.current.resolve<LanguageManager>(Managers.LANGUAGE_MANAGER);

  /* CONSTRUCTOR */
  constructor(props: ShellViewProps)
  {
    super(props);

    // Binding
    this.bind(new ShellViewModel(this), new ShellViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    return (
      <View style={this.style.shell}>


          <Switch>
            <Route path="/:language/reset" render={() => <ResetView />} />
            <Route path="/:language" render={() => <Redirect to={"/" + this.LanguageManager.language.toString() + "/reset"} />} />
          </Switch>


      </View>
    )
  }
}