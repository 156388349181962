import { ToastView , TranslationView } from '@levelapp/softfabric-ui';
import React from 'react';

export default class ToastHelper
{
    static getToast(title : string , type : 'error' | 'success' | 'info' | 'warning')
    {
        return (
        <ToastView type={type}>
            <TranslationView style={{color : 'white' , fontSize : 15}}>
                {title}
            </TranslationView>
        </ToastView>    
        )
    }
}