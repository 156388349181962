// app and web entry
import App from "./App";
import { AppRegistry, Platform } from "react-native";
import { unregister } from './registerServiceWorker';
import ClientConstants from "./ClientConstants";


// register the app
AppRegistry.registerComponent(ClientConstants.CLIENT_NAME, () => App);

// register the web
if (Platform.OS === "web") {
    AppRegistry.runApplication(ClientConstants.CLIENT_NAME, {
        rootTag: document.getElementById("root")
    });
}


unregister();

// caches.keys().then(function (names) {
//   for (let name of names)
//     caches.delete(name);
// });