import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import ButtonGenericViewState from './ButtonGenericViewState';
import ButtonGenericViewProps from './ButtonGenericViewProps';
import ButtonGenericViewStyle from './ButtonGenericViewStyle';
import ButtonGenericViewModel from './ButtonGenericViewModel';
import { TouchableWithoutFeedback, View, Text } from 'react-native';
import { LottieView } from '@levelapp/softfabric-ui';

export default class ButtonGenericView extends ContentView<ButtonGenericViewProps, ButtonGenericViewState, ButtonGenericViewModel, ButtonGenericViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ButtonGenericViewProps)
  {
    super(props);

    // Binding
    this.bind(new ButtonGenericViewModel(this), new ButtonGenericViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { container , cosmos, active  , text , textActive } = this.style

    return (
      <TouchableWithoutFeedback disabled={this.props.disable} onPress={() =>this.props.handleOnPress()}>
        {!this.props.isLoading ?
        <View style={cosmos}>
          <View style={[this.props.disable ? { opacity : 0.5 } : { opacity :1 } , container ,  active ]}>
            <Text style={[text ,  textActive ]}>{this.props.title}</Text>
          </View>
        </View>
         :
        (this.props.isLoading != undefined && this.props.isLoading && 
          <View style={cosmos}>
            <View style={[this.props.disable ? { opacity : 0.5 } : { opacity :1 } , container ,  active ]}>
              <LottieView width={30} height ={30} source={require('./assets/btn_loading.json')}/>
            </View>
          </View>
        
        )}
      </TouchableWithoutFeedback>
    );
  }
}