import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import ResetViewState from './ResetViewState';
import ResetViewProps from './ResetViewProps';
import ResetViewStyle from './ResetViewStyle';
import ResetViewModel from './ResetViewModel';
import {TranslationView} from '@levelapp/softfabric-ui';
import { View, Image } from 'react-native';
import TextInputGenericView from '../../components/textinputgeneric/TextInputGenericView';
import WebConstants from '../../../Common/Constants/WebConstants';
import ButtonGenericView from '../../components/buttongeneric/ButtonGenericView';
import ResponsiveHelper from '../../../Common/Helper/ResponsiveHelper';
import ClientConstants from '../../../ClientConstants';

export default class ResetView extends ContentView<ResetViewProps, ResetViewState, ResetViewModel, ResetViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:ResetViewProps)
  {
    super(props);

    // Binding
    this.bind(new ResetViewModel(this), new ResetViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const { headerText } = this.style;

    const imageStyle = ResponsiveHelper.isSmarthphone() ? this.style.imageForPhone : this.style.image;
    
    return (
      <View style={this.style.container}>
        
        <Image  style={imageStyle} 
                resizeMode='contain' 
                source={{ uri : ClientConstants.CLIENT_IMAGE}}/>

        <View style={[this.style.loginBox , ResponsiveHelper.isSmarthphone() && {width : '100%'} , this.state.success && { justifyContent :'center' , alignItems :'center'} ]}>
          {this.state.success ? 
          <View style={[this.style.loginContainer , ResponsiveHelper.isSmarthphone() && {paddingHorizontal : 10 , paddingVertical : 10}]}>
            <TranslationView style={{ fontSize : WebConstants.TEXTINPUT_FONT, fontFamily : WebConstants.APP_FONT , fontWeight : '700', justifyContent : 'center' , alignItems : 'center' , textAlign : 'center'}}>reset.title.success</TranslationView>
          </View>
          :
          <View style={[this.style.loginContainer , ResponsiveHelper.isSmarthphone() && {paddingHorizontal : 10 , paddingVertical : 10}]}>
            <TranslationView style={headerText}>
              reset.title
            </TranslationView>

            <TextInputGenericView value={this.state.email} shouldDisplayError={this.state.isCorrectEmail} shouldDisplayText={false} fontSizeEntry={WebConstants.TEXTINPUT_FONT} title={'form.email'} placeholder={'form.email.placeholder'} handleChangeText={(value : string) => this.binding.handleEmailChanged(value)}/>
            
            <TextInputGenericView value={this.state.password} shouldDisplayError={this.state.isCorrectPassword} shouldDisplayText={true}  fontSizeEntry={WebConstants.TEXTINPUT_FONT}  secure title={'form.password'} placeholder={'form.password.placeholder'} handleChangeText={(value : string) => this.binding.handlePasswordChanged(value)}/>
           
            <TextInputGenericView value={this.state.confirmpassword} shouldDisplayError={this.state.isCorrectConfirmPassword} shouldDisplayText={false}  fontSizeEntry={WebConstants.TEXTINPUT_FONT}  secure title={'form.confirmpassword'} placeholder={'form.confirmpassword.placeholder'} handleChangeText={(value : string) => this.binding.handleConfirmPasswordChanged(value)}/>

            <View style={{flexDirection : 'row'}}>
                <ButtonGenericView isLoading={this.state.isLoading} handleOnPress={() =>{ this.binding.resetPassword()}} title={this.binding.translate('reset.title')} disable={this.state.isLoading}/>            
            </View>
          </View>
          }
        </View>

      </View>
    );
  }
}