import {ContentViewModel,ContentView} from '@levelapp/softfabric';
import ButtonGenericViewState from './ButtonGenericViewState';
import ButtonGenericViewProps from './ButtonGenericViewProps';

export default class ButtonGenericViewModel extends ContentViewModel<ButtonGenericViewState, ButtonGenericViewProps>
{
      /* CONSTRUCTOR */
    constructor(view: ContentView)
    {
        super(view);

        this.initialState({}); // Initialize your state here

        // Bindings

    }
}