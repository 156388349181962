import React from 'react';
import {ContentView} from '@levelapp/softfabric';
import TextInputGenericViewState from './TextInputGenericViewState';
import TextInputGenericViewProps from './TextInputGenericViewProps';
import TextInputGenericViewStyle from './TextInputGenericViewStyle';
import TextInputGenericViewModel from './TextInputGenericViewModel';
import { TextInput, View, Text, Animated } from 'react-native';
import WebConstants from '../../../Common/Constants/WebConstants';
import { TranslationView } from '@levelapp/softfabric-ui';
import ResponsiveHelper from '../../../Common/Helper/ResponsiveHelper';


export default class TextInputGenericView extends ContentView<TextInputGenericViewProps, TextInputGenericViewState, TextInputGenericViewModel, TextInputGenericViewStyle>
{
  /* CONSTRUCTOR */
  constructor(props:TextInputGenericViewProps)
  {
    super(props);

    // Binding
    this.bind(new TextInputGenericViewModel(this), new TextInputGenericViewStyle(this));
  }

  /* RENDERING */
  render()
  {
    const {  textInput , container , containerError , error , errorText } = this.style

    const translation = this.binding.translation.interpolate(
      {
        inputRange : [0,1],
        outputRange : [10 , 0]
      })

    return (
      <View style={ResponsiveHelper.isSmarthphone() ? {paddingVertical : 0} :  {paddingVertical : 5}}>
        <View style={[container , !this.props.shouldDisplayError && containerError]}>
          
          <Animated.View style={[{zIndex : -1 , paddingBottom : 2,} , { transform : [{translateY : translation}]}]}>
            <Text style={{color : WebConstants.TEXT_INPUT_COLOR}}>{this.binding.translate(this.props.placeholder)}</Text>
          </Animated.View>
        
          <TextInput
            value={this.props.value}
            secureTextEntry={this.props.secure != undefined && this.props.secure ? true : false}
            onChangeText={(value: string) => this.binding.handleChangeValue(value)} 
            onBlur={() => this.binding.leaveAnimation()}
            onFocus={() => this.binding.launchAnimation()}
            style={[textInput , {fontSize : this.props.fontSizeEntry}]} />
        </View>

        { this.props.shouldDisplayText && <TranslationView style={[error,errorText , !this.props.shouldDisplayError && { color : WebConstants.ERROR_COLOR }]}>error.password.length</TranslationView>}
      </View>
      
    );
  }
}